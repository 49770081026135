import React, { useEffect, useRef, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";

const ShowInscreenGraphic = ({
    inscreenGraphicOptions,
    defaultOption,
    onClickHandler,
}) => {
    return inscreenGraphicOptions?.map((option) => (
        <div className="form-check mt-1 form-check-inline" key={option.id}>
            <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id={option.id}
                value={option.value}
                defaultChecked={option.value === defaultOption}
                onClick={() => onClickHandler(option.value)}
            />
            <label className="form-check-label" htmlFor={option.id}>
                {option.label}
            </label>
        </div>
    ));
};

const ShowSize = ({ series, setProduct }) => {
    return series?.size.map((option, index) => (
        <React.Fragment key={option.id}>
            <input
                type="radio"
                className="btn-check"
                name="options"
                id={option.id}
                autoComplete="off"
                defaultChecked={index === 0}
                onClick={() => {
                    setProduct(option.model);
                }}
            />
            <label className="btn btn-yellow" htmlFor={option.id}>
                <small>{option.size}"</small>
            </label>
        </React.Fragment>
    ));
};
const useLocalStorage = (key, value) => {
    useEffect(() => {
        localStorage.setItem(key, value);
    }, [key, value]);
};

const ProductCard = ({
    series,
    setModelName,
    defaultArModel,
    collapseId,
    seriesName,
}) => {
    const [img, setImg] = useState(Object.keys(series.imageMapping)[0]);
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [product, setProduct] = useState(defaultArModel);
    const cardRef = useRef(null);

    const openVB = (img) => {
        let model = product + (series.labelfeet ? "F" : "") + img;
        console.log("Model: " + model);
        let modelViewer = document.querySelector(model);
        console.log("Can activate AR: " + modelViewer.canActivateAR);
        modelViewer.activateAR();
    };

    useEffect(() => {
        const collapseElement = document.getElementById(collapseId);

        const handleShow = () => setIsCollapsed(false);

        const handleHide = () => setIsCollapsed(true);

        collapseElement.addEventListener("show.bs.collapse", handleShow);
        collapseElement.addEventListener("hide.bs.collapse", handleHide);

        return () => {
            collapseElement.removeEventListener("show.bs.collapse", handleShow);
            collapseElement.removeEventListener("hide.bs.collapse", handleHide);
        };
    }, [collapseId]);

    useEffect(() => {
        if (!isCollapsed && cardRef.current) {
            setTimeout(() => {
                cardRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                });
            }, 250)
            // setModelName(defaultArModel); // 假設你想設置模型名稱
            // 其他需要的操作
        }
    }, [isCollapsed])

    useLocalStorage("prodcut_img", series.imageMapping[img]);
    useLocalStorage("product", product.substring(1));
    useLocalStorage("type", img === "crop" ? "corp" : "edu");

    useEffect(() => {
        setImg(Object.keys(series.imageMapping)[0]);
    }, [series]);

    useEffect(() => {
        if (isCollapsed === false) {
            localStorage.setItem("product", product.substring(1));
        }
    }, [isCollapsed]);

    return (
        <div className="accordion-item">
            <form>
                <button
                    className="accordion-button collapsed justify-content-center"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#${collapseId}`}
                    aria-expanded="false"
                    aria-controls={collapseId}
                >
                    <div className="row">
                        <div className="col-6 d-flex align-items-center">
                            <h4 className="ps-1">
                                {seriesName} <br></br>
                                {series?.name}
                            </h4>
                        </div>
                        <div className="col-6">
                            <img
                                src={series?.imageMapping[img]}
                                className="img-fluid"
                                alt={series?.name}
                            ></img>
                        </div>

                        <div className="col-12">
                            <h5 className="text-uppercase tap-expand">
                                {isCollapsed === true && "Tap to expand"}
                            </h5>
                        </div>
                    </div>
                </button>

                <div
                    id={collapseId}
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                    ref={!isCollapsed ? cardRef : null}
                >
                    <div className="accordion-body">
                        <div className="row px-3">
                            <hr></hr>
                            <div className="col-12 mb-3">
                                <small className="small-title">
                                    Select inscreen graphic
                                </small>
                                <br></br>
                                <ScrollContainer className="form-inscreen">
                                    <ShowInscreenGraphic
                                        inscreenGraphicOptions={
                                            series?.inscreen_graphic
                                        }
                                        defaultOption={
                                            Object.keys(series.imageMapping)[0]
                                        }
                                        onClickHandler={setImg}
                                    />
                                </ScrollContainer>
                            </div>

                            <hr></hr>
                            <div className="col-12 mb-3">
                                <small className="small-title">
                                    Select Size
                                </small>
                                <br></br>
                                <ScrollContainer >
                                    <div className="form-check-center mt-1">
                                        <ShowSize
                                            series={series}
                                            setProduct={setProduct}
                                        />
                                    </div>
                                </ScrollContainer>
                            </div>
                            <hr></hr>
                            <div className="col-12">
                                <small className="small-title">
                                    What would you like to do?
                                </small>
                                <br></br>
                                <div className="d-grid gap-2 col-10 mx-auto mt-1">
                                    <button
                                        className="btn btn-red"
                                        type="button"
                                        onClick={() => openVB(img)}
                                    >
                                        <h5>
                                            {series?.feet
                                                ? "Place in space"
                                                : "Place on wall"}
                                        </h5>
                                    </button>
                                    <button
                                        className="btn btn-white"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#customsheet"
                                        onClick={() => {
                                            setModelName(series?.name);
                                        }}
                                    >
                                        <h5>Create Sales Sheet</h5>
                                    </button>
                                    <button
                                        className="btn btn-white"
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target="#demo"
                                        disabled
                                    >
                                        <h5>Request a demo</h5>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ProductCard;
