import { Box, Text, CloseButton, Image, VStack } from "@chakra-ui/react";
import CustomModal from "./CustomModal";
import logo from "../../assets/about/ViewARLogov2.svg";
import { useNavigate } from "react-router-dom";

const About = ({ setOpen, open }) => {
    const textStyle = {
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "14px",
        fontFamily: "Gotham",
        textAlign: "center",
        margin: "0",
    };
    const history = useNavigate();

    const handleClicked = (path) => {
        setOpen(false);
        history(path);
    };

    return (
        <CustomModal isOpen={open} onRequestClose={() => setOpen(false)}>
            <Box boxShadow={"0px -5px 8px -6px #00000040"} padding="2">
                <Box
                    display="flex"
                    justifyContent="flex-end"
                    paddingRight="12px"
                >
                    <CloseButton onClick={() => setOpen(false)} />
                </Box>
                <VStack alignItems="center" spacing={4}>
                    <Image src={logo} alt="logo" w="220px" mt="20px" />
                    <Text {...textStyle} w="203px" h="39px">
                        Need more info on how to use this app? Click Below
                    </Text>
                    <Box
                        w="230px"
                        h="48px"
                        bg="#DB0025"
                        borderRadius="12px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => handleClicked("/help")}
                    >
                        <Text
                            {...textStyle}
                            fontWeight="600"
                            color="white"
                            fontFamily="Open Sans"
                        >
                            HOW TO USE VIEW AR
                        </Text>
                    </Box>
                    <VStack
                        w="203px"
                        spacing={2}
                        alignItems="center"
                        mt="150px"
                    >
                        <Text {...textStyle} whiteSpace="pre-line">
                            {
                                "View AR is created and by ViewSonic Corporation.\nAll Rights Reserved 2023"
                            }
                        </Text>
                        <Text {...textStyle} color="#B2B2B2">
                            The products you see here are for demonstration
                            purposes only and may differ from actual physical
                            products in size and appearance
                        </Text>
                    </VStack>
                </VStack>
            </Box>
        </CustomModal>
    );
};

export default About;
