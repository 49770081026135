/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Header.css";
import ScrollContainer from "react-indiana-drag-scroll";
import About from "../About/About";

const navItems = [
    { path: "/", title: "Viewboards" },
    { path: "/cde", title: "Commercial Display" },
    { path: "/viewled", title: "Direct ViewLED" },
    { path: "/stand", title: "Stand Alone" },
];

function Header() {
    const [open, setOpen] = useState(false);
    const history = useNavigate();
    const location = useLocation(); // 使用 useLocation 獲取當前路由
    const [active, setActive] = useState({
        "/": true,
        "/cde": false,
        "/viewled": false,
        "/stand": false,
    });

    const NavItem = ({ path, title, active }) => {
        return (
            <li className="nav-item">
                <a
                    className={active[path] ? "nav-link active" : "nav-link"}
                    onClick={() => handleClicked(path)}
                >
                    <h4>{title}</h4>
                </a>
            </li>
        );
    };

    const handleClicked = (path) => {
        setActive((prev) => {
            const newActive = {};
            for (let key in prev) {
                newActive[key] = key === path;
            }
            return newActive;
        });
        history(path);
    };

    useEffect(() => {
        setActive((prev) => {
          const newActive = {};
          const currentPath = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;
          for (let key in prev) {
            const trimmedKey = key.endsWith('/') ? key.slice(0, -1) : key;
            newActive[key] = trimmedKey === currentPath;
          }
          return newActive;
        });
      }, [location.pathname]);
      

    return (
        <>
            <About setOpen={setOpen} open={open}></About>
            <div className="shadows sticky">
                <div className="viewar">
                    <span className="view">View</span>
                    <span className="ar">AR</span>
                </div>

                <div className="row justify-content-between header-home">
                    <div className="col-10">
                        <h5 className="mb-3 ls-8">HOME</h5>
                    </div>
                    <div className="col-2">
                        <i
                            className="bi bi-list list"
                            onClick={() => setOpen(true)}
                        ></i>
                    </div>
                </div>
                <h4 className="mb-3">Select your product</h4>
                <ul className="nav">
                    <ScrollContainer className="scrollbar">
                        {navItems.map((item) => (
                            <NavItem
                                key={item.path}
                                path={item.path}
                                title={item.title}
                                active={active}
                            />
                        ))}
                    </ScrollContainer>
                </ul>
            </div>
        </>
    );
}

export default Header;
