import ifp6552 from "../../assets/viewboard/ifp52/IFP_6552.glb";
import ifp6552crop from "../../assets/viewboard/ifp52/IFP_6552_crop.glb";
import ifp6552class from "../../assets/viewboard/ifp52/IFP_6552_class.glb";
import ifp6552board from "../../assets/viewboard/ifp52/IFP_6552_board.glb";
import ifp7552 from "../../assets/viewboard/ifp52/IFP_7552.glb";
import ifp7552crop from "../../assets/viewboard/ifp52/IFP_7552_crop.glb";
import ifp7552class from "../../assets/viewboard/ifp52/IFP_7552_class.glb";
import ifp7552board from "../../assets/viewboard/ifp52/IFP_7552_board.glb";
import ifp8652 from "../../assets/viewboard/ifp52/IFP_8652.glb";
import ifp8652crop from "../../assets/viewboard/ifp52/IFP_8652_crop.glb";
import ifp8652class from "../../assets/viewboard/ifp52/IFP_8652_class.glb";
import ifp8652board from "../../assets/viewboard/ifp52/IFP_8652_board.glb";
import ifp6532 from "../../assets/viewboard/ifp32/IFP_6532.glb";
import ifp6532crop from "../../assets/viewboard/ifp32/IFP_6532_crop.glb";
import ifp6532class from "../../assets/viewboard/ifp32/IFP_6532_class.glb";
import ifp6532board from "../../assets/viewboard/ifp32/IFP_6532_board.glb";
import ifp7532 from "../../assets/viewboard/ifp32/IFP_7532.glb";
import ifp7532crop from "../../assets/viewboard/ifp32/IFP_7532_crop.glb";
import ifp7532class from "../../assets/viewboard/ifp32/IFP_7532_class.glb";
import ifp7532board from "../../assets/viewboard/ifp32/IFP_7532_board.glb";
import ifp8632 from "../../assets/viewboard/ifp32/IFP_8632.glb";
import ifp8632crop from "../../assets/viewboard/ifp32/IFP_8632_crop.glb";
import ifp8632class from "../../assets/viewboard/ifp32/IFP_8632_class.glb";
import ifp8632board from "../../assets/viewboard/ifp32/IFP_8632_board.glb";
import ifp6562 from "../../assets/viewboard/ifp62/IFP_6562.glb";
import ifp6562crop from "../../assets/viewboard/ifp62/IFP_6562_crop.glb";
import ifp6562class from "../../assets/viewboard/ifp62/IFP_6562_class.glb";
import ifp6562board from "../../assets/viewboard/ifp62/IFP_6562_board.glb";
import ifp7562 from "../../assets/viewboard/ifp62/IFP_7562.glb";
import ifp7562crop from "../../assets/viewboard/ifp62/IFP_7562_crop.glb";
import ifp7562class from "../../assets/viewboard/ifp62/IFP_7562_class.glb";
import ifp7562board from "../../assets/viewboard/ifp62/IFP_7562_board.glb";
import ifp8662 from "../../assets/viewboard/ifp62/IFP_8662.glb";
import ifp8662crop from "../../assets/viewboard/ifp62/IFP_8662_crop.glb";
import ifp8662class from "../../assets/viewboard/ifp62/IFP_8662_class.glb";
import ifp8662board from "../../assets/viewboard/ifp62/IFP_8662_board.glb";
import ifp6550 from "../../assets/viewboard/ifp50/IFP_6550.glb";
import ifp6550crop from "../../assets/viewboard/ifp50/IFP_6550_crop.glb";
import ifp6550class from "../../assets/viewboard/ifp50/IFP_6550_class.glb";
import ifp6550board from "../../assets/viewboard/ifp50/IFP_6550_board.glb";
import ifp7550 from "../../assets/viewboard/ifp50/IFP_7550.glb";
import ifp7550crop from "../../assets/viewboard/ifp50/IFP_7550_crop.glb";
import ifp7550class from "../../assets/viewboard/ifp50/IFP_7550_class.glb";
import ifp7550board from "../../assets/viewboard/ifp50/IFP_7550_board.glb";
import ifp8650 from "../../assets/viewboard/ifp50/IFP_8650.glb";
import ifp8650crop from "../../assets/viewboard/ifp50/IFP_8650_crop.glb";
import ifp8650class from "../../assets/viewboard/ifp50/IFP_8650_class.glb";
import ifp8650board from "../../assets/viewboard/ifp50/IFP_8650_board.glb";
import ifp105s from "../../assets/viewboard/ifp105s/ifp105_edu.glb";
import ifp105sclass from "../../assets/viewboard/ifp105s/ifp105_class.glb";
import ifp105scrop from "../../assets/viewboard/ifp105s/ifp105_corporate.glb";
import ifp105sboard from "../../assets/viewboard/ifp105s/ifp105_boardroom.glb";
import ifp9850 from "../../assets/viewboard/ifp9850/ifp9850_education.glb";
import ifp9850class from "../../assets/viewboard/ifp9850/ifp9850_classroom.glb";
import ifp9850crop from "../../assets/viewboard/ifp9850/ifp9850_corporate.glb";
import ifp9850board from "../../assets/viewboard/ifp9850/ifp9850_boardroom.glb";


// viewled
import ld135Glb from "../../assets/viewled/GLB/LD135_151.glb";
import ld163Glb from "../../assets/viewled/GLB/LD163_181.glb";
import ld216Glb from "../../assets/viewled/GLB/LD216_251.glb";




// stand
import lds135Glb from "../../assets/viewled/GLB/LDS135_151_Elevator.glb";
import ldf135Glb from "../../assets/viewled/GLB/LD135_151_Feet.glb";
import ldf163Glb from "../../assets/viewled/GLB/LDM163_181_Feet.glb";



//cde
import cde4330board from "../../assets/viewboard/CDE30/CDE4330_Boardroom.glb";
import cde4330campus from "../../assets/viewboard/CDE30/CDE4330_Campus.glb";
import cde4330crop from "../../assets/viewboard/CDE30/CDE4330_Corporate.glb";
import cde5530board from "../../assets/viewboard/CDE30/CDE5530_Boardroom.glb";
import cde5530campus from "../../assets/viewboard/CDE30/CDE5530_Campus.glb";
import cde5530crop from "../../assets/viewboard/CDE30/CDE5530_Corporate.glb";
import cde6530board from "../../assets/viewboard/CDE30/CDE6530_Boardroom.glb";
import cde6530campus from "../../assets/viewboard/CDE30/CDE6530_Campus.glb";
import cde6530crop from "../../assets/viewboard/CDE30/CDE6530_Corporate.glb";
import cde7530board from "../../assets/viewboard/CDE30/CDE7530_Boardroom.glb";
import cde7530campus from "../../assets/viewboard/CDE30/CDE7530_Campus.glb";
import cde7530crop from "../../assets/viewboard/CDE30/CDE7530_Corporate.glb";
import cde8630board from "../../assets/viewboard/CDE30/CDE8630_Boardroom.glb";
import cde8630campus from "../../assets/viewboard/CDE30/CDE8630_Campus.glb";
import cde8630crop from "../../assets/viewboard/CDE30/CDE8630_Corporate.glb";
import cde9830board from "../../assets/viewboard/CDE30/CDE9830_Boardroom.glb";
import cde9830campus from "../../assets/viewboard/CDE30/CDE9830_Campus.glb";
import cde9830crop from "../../assets/viewboard/CDE30/CDE9830_Corporate.glb";

let models = {
    "ifp6552edu": ifp6552,
    "ifp7552edu": ifp7552,
    "ifp8652edu": ifp8652,
    "ifp6550edu": ifp6550,
    "ifp7550edu": ifp7550,
    "ifp8650edu": ifp8650,
    "ifp6532edu": ifp6532,
    "ifp7532edu": ifp7532,
    "ifp8632edu": ifp8632,
    "ifp6562edu": ifp6562,
    "ifp7562edu": ifp7562,
    "ifp8662edu": ifp8662,
    "ifp6552crop": ifp6552crop,
    "ifp7552crop": ifp7552crop,
    "ifp8652crop": ifp8652crop,
    "ifp6550crop": ifp6550crop,
    "ifp7550crop": ifp7550crop,
    "ifp8650crop": ifp8650crop,
    "ifp6532crop": ifp6532crop,
    "ifp7532crop": ifp7532crop,
    "ifp8632crop": ifp8632crop,
    "ifp6562crop": ifp6562crop,
    "ifp7562crop": ifp7562crop,
    "ifp8662crop": ifp8662crop,
    "ifp6552class": ifp6552class,
    "ifp7552class": ifp7552class,
    "ifp8652class": ifp8652class,
    "ifp6550class": ifp6550class,
    "ifp7550class": ifp7550class,
    "ifp8650class": ifp8650class,
    "ifp6532class": ifp6532class,
    "ifp7532class": ifp7532class,
    "ifp8632class": ifp8632class,
    "ifp6562class": ifp6562class,
    "ifp7562class": ifp7562class,
    "ifp8662class": ifp8662class,
    "ifp6552board": ifp6552board,
    "ifp7552board": ifp7552board,
    "ifp8652board": ifp8652board,
    "ifp6550board": ifp6550board,
    "ifp7550board": ifp7550board,
    "ifp8650board": ifp8650board,
    "ifp6532board": ifp6532board,
    "ifp7532board": ifp7532board,
    "ifp8632board": ifp8632board,
    "ifp6562board": ifp6562board,
    "ifp7562board": ifp7562board,
    "ifp8662board": ifp8662board,
    "ifp105sedu": ifp105s,
    "ifp105sclass": ifp105sclass,
    "ifp105scrop": ifp105scrop,
    "ifp105sboard": ifp105sboard,
    "ifp9850-4edu": ifp9850,
    "ifp9850-4class": ifp9850class,
    "ifp9850-4crop": ifp9850crop,
    "ifp9850-4board": ifp9850board,
    "LD135-151crop": ld135Glb,
    "LD163-181crop": ld163Glb,
    "LDP216-251crop": ld216Glb,
    "LDS135-151crop": lds135Glb,
    "LD135-151Fcrop": ldf135Glb,
    "LDM163-181Fcrop": ldf163Glb,
    "CDE4330board": cde4330board,
    "CDE4330campus": cde4330campus,
    "CDE4330crop": cde4330crop,
    "CDE5530board": cde5530board,
    "CDE5530campus": cde5530campus,
    "CDE5530crop": cde5530crop,
    "CDE6530board": cde6530board,
    "CDE6530campus": cde6530campus,
    "CDE6530crop": cde6530crop,
    "CDE7530board": cde7530board,
    "CDE7530campus": cde7530campus,
    "CDE7530crop": cde7530crop,
    "CDE8630board": cde8630board,
    "CDE8630campus": cde8630campus,
    "CDE8630crop": cde8630crop,
    "CDE9830board": cde9830board,
    "CDE9830campus": cde9830campus,
    "CDE9830crop": cde9830crop,
};

export default models