import ProdcutSVG from "../../assets/onboardscreen/Select ProdcutSVG.svg";
import progress1 from "../../assets/onboardscreen/progress1.svg";
import progress2 from "../../assets/onboardscreen/progress2.svg";
import progress3 from "../../assets/onboardscreen/progress3.svg";
import progress4 from "../../assets/onboardscreen/progress4.svg";
import progress5 from "../../assets/onboardscreen/progress5.svg";
import progress6 from "../../assets/onboardscreen/progress6.svg";
import progress7 from "../../assets/onboardscreen/progress7.svg";
import progress8 from "../../assets/onboardscreen/progress8.svg";
import ScanSpace from "../../assets/onboardscreen/Scan SpaceSVG.svg";
import bookaDemo from "../../assets/onboardscreen/book a demoSVG.svg";
import PositionProduct from "../../assets/onboardscreen/positionProductandFinger.svg";
import TakePhoto from "../../assets/onboardscreen/Take PhotoSVG.svg";
import CloseViewer from "../../assets/onboardscreen/Close ViewerSVG.svg";
import CreateSalesSheet from "../../assets/onboardscreen/Create Sales sheetSVG.svg";
import SalesSheet from "../../assets/onboardscreen/Sales sheetSVG.svg";

export const stageInfo = [
    {
        pic: ProdcutSVG,
        title: "Select a product",
        content: [
            "Select the product you would like to see displayed on in your space.",
        ],
        progressBar: progress1,
    },
    {
        pic: ScanSpace,

        title: "Scan your space",
        content: [
            "Move your phone around to scan and map the space you’d like to render the product in.",
        ],
        progressBar: progress2,
    },
    {
        pic: PositionProduct,

        title: "Place product",
        content: [
            "Move the product to position it where you want in your space.",
            "You can use one-finger swipe gestures to make sure the product is positioned just how you want it.",
        ],
        progressBar: progress3,
    },
    {
        pic: TakePhoto,

        title: "Take a photo",
        content: [
            "To capture an image of the product in the space, tap the camera capture button.",
            "(You’ll need this later for your custom sales sheet)",
        ],
        progressBar: progress4,
    },
    {
        pic: CloseViewer,

        title: "Close the Viewer",
        content: [
            "Exit the viewer by tapping the X in the top left corner of your screen",
        ],
        progressBar: progress5,
    },
    {
        pic: CreateSalesSheet,

        title: "Custom Salesheet",
        content: [
            "Create your very own custom sales sheet with the photo you’ve taken. Simply fill out the form and upload your image",
        ],
        progressBar: progress6,
    },
    {
        pic: SalesSheet,

        title: "Recieve your salesheet!",
        content: [
            "Create your very own custom sales sheet with the photo you’ve taken. Simply fill out the form and upload your image",

        ],
        progressBar: progress7,
    },
    {
        pic: bookaDemo,

        title: "Or, Book a demo (Coming Soon)",
        content: [
            "Create your very own custom sales sheet with the photo you’ve taken. Simply fill out the form and upload your image",

        ],
        progressBar: progress8,
    },
];