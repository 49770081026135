import React, { useState, useEffect, useRef } from "react";
import "./onboardscreen.css";
import { Box, Image, Flex } from "@chakra-ui/react";
import OnBoardingLogo from "./unit/Logo/OnBoardingLogo";
import { stageInfo } from "./OnboardScreenInfo";

import Stage from "./unit/Stage";
const OnboardScreen = () => {
    const [isloading, setIsLoading] = useState(true);

    const handleAnimationEnd = () => {
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    };

    

    return (
        <Box
            position="fixed"
            top="0"
            right="0"
            bottom="0"
            height="100vh"
            left="0"
            zIndex="9999"
            bgColor="white"
            maxHeight="-webkit-fill-available"
        >
            {isloading ? (
                <Flex h="100%" alignItems="center" justifyContent="center">
                    <OnBoardingLogo onAnimationEnd={handleAnimationEnd} />
                </Flex>
            ) : (
                <Stage stageInfo={stageInfo} />
            )}
            {/* 預先渲染下一階段（stage + 1）的圖片 */}

            <div
                style={{
                    opacity: 0,
                    position: "absolute",
                    pointerEvents: "none",
                }}
            >
                <img
                    src={stageInfo[0]?.pic}
                    alt="next product"
                    style={{ height: "200px", maxWidth: "200px" }}
                />
                <img src={stageInfo[0]?.progressBar} alt="stage1" />
            </div>
        </Box>
    );
};

export default OnboardScreen;
