import React, { useState } from "react";
import Select, { createFilter } from "react-select";
import { useSwipeable } from "react-swipeable";
import { pdf } from "@react-pdf/renderer";
import SalesheetPDF from "../Salesheet/SalesheetPDF";
import { saveAs } from "file-saver";
import ReactGA from "react-ga4";
const CustomSalesheetModal = ({
    imgRef,
    country,
    setCountry,
    countryList,
    modelName,
    modelRef,
    nameRef,
    emailRef,
    setOpen2,
}) => {
    const filterConfig = {}; // Define or pass filter configuration if needed
    const threshold = window.screen.height / 2; // Threshold for closing the modal
    const [draggedStyle, setDraggedStyle] = useState({});
    const product = localStorage.getItem("product");
    const [uploadImg, setUploadImg] = useState(null);
    const [emailCheck, setEmailCheck] = useState(true);
    const [imgCheck, setImgCheck] = useState(true);

    const handlers = useSwipeable({
        onSwipedDown: (e) => {
            if (e.deltaY >= threshold) {
                onSwipeDown();
            } else {
                bounceBack();
            }
        },
        onSwiping: (e) => {
            if (e.deltaY > 0) {
                onDragDown(e);
            }
        },
    });

    const checkEmail = () => {
        const email = emailRef.current.value;
        const emailRegExp = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const emailReg = emailRegExp.test(email);
        if (email === "" || emailReg === true) {
            setEmailCheck(true);
        } else if (emailReg === false) {
            setEmailCheck(false);
        }
    };

    const onDragDown = (e) => {
        const transform = `translateY(${e.deltaY}px)`;
        setDraggedStyle({
            transform: transform,
            backfaceVisibility: "hidden",
        });
    };

    const bounceBack = () => {
        const duration = 300;
        setDraggedStyle({
            transform: "translateY(0px)",
            transition: `transform ${duration}ms`,
            backfaceVisibility: "hidden",
        });
        setTimeout(() => {
            setDraggedStyle({});
        }, duration);
    };

    const onSwipeDown = () => {
        const transform = `translateY(${window.screen.height}px)`;
        const duration = 30;
        setDraggedStyle({
            transform: transform,
            transition: `transform ${duration}ms`,
            backfaceVisibility: "hidden",
        });

        setTimeout(() => {
            setDraggedStyle({});
            document.getElementById("closeCustomSheet").click(); // Trigger click on the hidden button
        }, duration);
    };

    const onUploadPicture = () => {
        const file = imgRef.current.files[0];
        const blobURL = URL.createObjectURL(file);
        console.log("blobURL", blobURL);
        setUploadImg(blobURL);
    };

    const listSpec = (specs) => {
        const organizedData = specs.reduce((acc, spec) => {
            const groupName = spec.groupname.toUpperCase();
            const showname = spec.showname;
            const value = spec.value;

            if (!acc[groupName]) {
                acc[groupName] = [];
            }
            if (value !== "N/A") {
                acc[groupName].push({
                    showname,
                    value,
                });
            }

            return acc;
        }, {});

        const result = Object.entries(organizedData)
            .map(([groupName, specs]) => ({
                [groupName]: specs,
            }))
            .filter((obj) => Object.values(obj)[0].length > 0);

        return result;
    };

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleCreateSheet = async (event) => {
        const product = localStorage.getItem("product");

        event.preventDefault();
        const img = imgRef.current.files[0];
        const name = nameRef.current.value;
        const email = emailRef.current.value;
        const model = localStorage.getItem("product");
        const type = localStorage.getItem("type");
        const emailRegExp = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        const emailReg = emailRegExp.test(email);
        if (img === undefined) {
            setImgCheck(false);
        } else {
            setImgCheck(true);
        }
        if (emailReg === true) {
            setEmailCheck(true);
        } else if (emailReg === false) {
            setEmailCheck(false);
        }

        if (emailReg && img !== undefined) {
            const base64 = await convertBase64(img);
            const data = {
                img: base64,
                name: name,
                email: email,
                country: country,
                model: model,
                type: type,
            };
            let res = await fetch(
                "https://vgsa157d2e.execute-api.us-west-1.amazonaws.com/gpg/salesheetv2",
                { method: "post", body: JSON.stringify(data) }
            );
            if (res.status === 200) {
                setOpen2(true);
            }
            ReactGA.event("generate_sales_sheet", {
                customer: name,
                email: email,
                country: country.value,
                model: model,
                type: type,
            });
        }

        const res = await fetch(
            "https://vgsa157d2e.execute-api.us-west-1.amazonaws.com/gpg/productspecs",
            {
                method: "post",
                body: JSON.stringify({ productName: product }),
            }
        );
        const result = await res.json();
        const content = result.body.product;
        const spec = listSpec(result.body.product.specs);
        const url2 = localStorage.getItem("prodcut_img");

        const doc = (
            <SalesheetPDF
                imgUrl={uploadImg}
                type={type}
                spec={spec}
                content={content}
                product={product}
                img1={uploadImg}
                img2={url2}
            />
        );

        const pdfBlob = await pdf(doc).toBlob();
        const fileName = `${product}.pdf`;
        const blob = new Blob([pdfBlob], { type: "application/octet-stream" });
        saveAs(blob, fileName);
    };

    return (
        <div
            className="modal fade"
            id="customsheet"
            tabIndex="-1"
            aria-labelledby="customsheet"
            aria-hidden="true"
            style={draggedStyle}
            {...handlers}
        >
            <button
                type="button"
                className="d-none"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeCustomSheet"
            ></button>
            <div className="modal-dialog">
                <div className="modal-content animate-bottom">
                    <div className="modal-header">
                        <h4 className="modal-title mx-auto" id="customsheet">
                            <div className="swipe-line"></div>
                            Custom Salesheet
                        </h4>
                        <button
                            type="button"
                            className="d-none"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeCustomSheet"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <label
                                htmlFor="exampleInputEmail1"
                                className="form-label"
                            >
                                Product photo
                            </label>
                            <div className="input-group mb-3">
                                <input
                                    type="file"
                                    className={
                                        !imgCheck
                                            ? "form-control border-red"
                                            : "form-control"
                                    }
                                    id="inputGroupFile01"
                                    accept="image/*"
                                    ref={imgRef}
                                    onChange={onUploadPicture}
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label"
                                >
                                    Full name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Ben Smith"
                                    required
                                    ref={nameRef}
                                />
                            </div>
                            <div className="mb-3">
                                <div className="row">
                                    <div className="col">
                                        <label
                                            htmlFor="exampleInputEmail1"
                                            className="form-label"
                                        >
                                            Email
                                        </label>
                                    </div>
                                    <div className="col d-flex justify-content-end">
                                        {!emailCheck && (
                                            <p className="error">
                                                Error: Please enter valid email
                                                address
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <input
                                    type="email"
                                    className={
                                        !emailCheck
                                            ? "form-control border-red"
                                            : "form-control"
                                    }
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder="Bensmith@greatclient.com"
                                    required
                                    pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
                                    ref={emailRef}
                                    onChange={checkEmail}
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label"
                                >
                                    Country
                                </label>
                                <Select
                                    value={country}
                                    onChange={(val) => setCountry(val)}
                                    options={countryList}
                                    placeholder="Select from list"
                                    filterOption={createFilter(filterConfig)}
                                />
                            </div>
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleInputEmail1"
                                    className="form-label"
                                >
                                    Model Name
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="exampleInputEmail1"
                                    aria-describedby="emailHelp"
                                    placeholder={modelName}
                                    required
                                    ref={modelRef}
                                    disabled
                                />
                            </div>
                            <div className="d-grid gap-2 col-9 mx-auto mt-1">
                                <button
                                    className="btn btn-red"
                                    type="submit"
                                    onClick={handleCreateSheet}
                                >
                                    <h5>Create Sale Sheet</h5>
                                    <div className="d-none"></div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomSalesheetModal;
