import React from "react";
// import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";
import headerEdu from "../../assets/salesheet/Header_EDU.png";
import headerCorp from "../../assets/salesheet/Header_Corp.png";
import footerCorp from "../../assets/salesheet/Footer_Corp.png";
import footerEdu from "../../assets/salesheet/Footer_EDU.png";
import {
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
    header: {
        position: "relative",
    },
    footer: {
        position: "relative",
    },
    headerImg: {
        display: "block",
        width: "100%",
    },
    headerDiv: {
        position: "absolute",
        left: 80,
        top: 55,
    },
    smallText: {
        fontSize: 18,
    },
    headerH3: {
        fontWeight: 600,
        fontSize: 52,
    },
    salesheetH5: {
        fontSize: 28,
    },
    body: {
        padding: "20px 24px",
    },
    bodyImg: {
        width: "100%",
        borderRadius: 8,
    },
    border: {
        marginTop: 30,
        border: "2px solid #76C2BD",
        borderRadius: 8,
        padding: 42,
        flexDirection: "row",
        alignItems: "center",
    },
    border2: {
        marginTop: 30,
        border: "2px solid #76C2BD",
        borderRadius: 8,
        padding: 10,
    },
    tag: {
        marginTop: 30,
    },
    borderImg: {
        width: 360,
        height: "100%",
        marginRight: 20,
    },
    borderH5: {
        marginBottom: 8,
    },
    box: {
        width: 300,
        height: 300,
        backgroundColor: "#E6E8F4",
        borderRadius: 8,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
        margin: 20,
        textAlign: "center",
        padding: "0 30px",
        fontWeight: 500,
    },
    boxs: {
        backgroundColor: "#fff",
        margin: "0 auto",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
    },
    white: {
        color: "#fff",
    },
});

const SalesheetPDF = ({  type, spec, content, product, img1, img2 }) => {
    return (
        <Document>
            <Page wrap={false}>
                <View
                    style={{
                        position: "relative",
                    }}
                >
                    <Image
                        src={type === "edu" ? headerEdu : headerCorp}
                        alt="header"
                    />
                    <View
                        style={{
                            left: 60,
                            position: "absolute",
                            top: 40,
                            color: type === "edu" ? "black" : "white",
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 12,
                                fontWeight: 1000,
                            }}
                        >
                            ViewSonic®
                        </Text>
                        <Text
                            style={{
                                fontSize: 30,
                                fontWeight: 1000,
                            }}
                        >
                            {product && product.toUpperCase()}
                        </Text>
                        <Text
                            tyle={{
                                fontSize: 20,
                                fontWeight: 1000,
                            }}
                        >
                            {content.productLine}
                        </Text>
                    </View>
                </View>
                <View style={styles.body}>
                    <Image src={img1} alt="disable" style={styles.bodyImg} />
                    <View
                        style={{
                            marginTop: 30,
                            border: "2px solid #76C2BD",
                            borderRadius: 8,
                            padding: 20,
                            flexDirection: "row",
                            alignItems: "center",
                        }}
                        resizeMode="contain"
                    >
                        <Image
                            src={img2}
                            alt="disable"
                            style={{
                                width: 150,
                                height: "auto",
                                marginRight: 20,
                            }}
                        />
                        <View
                            style={{
                                width: 360,
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 20,
                                    fontWeight: 800,
                                    marginBottom: 8,
                                }}
                            >
                                Overview
                            </Text>
                            <Text
                                style={{
                                    fontSize: 10,
                                    fontWeight: 400,
                                    marginTop: 0,
                                    marginBottom: 1,
                                    textAlign: "justify",
                                    lineHeight: 1.3,
                                }}
                            >
                                {content.overview}
                            </Text>
                        </View>
                    </View>
                </View>
                {/* </Page>
            <Page> */}
                <View style={styles.body}>
                    <View style={styles.tag}>
                        <Text>Benefit Highlight</Text>
                        <View
                            style={{
                                flexDirection: "row",
                                display: "flex",
                                flexWrap: "wrap",
                                fontSize: 10,
                                margin: 10,
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {content?.tagline &&
                                content?.tagline.map((text, index) => (
                                    <View
                                        style={{
                                            width: 150,
                                            height: 150,
                                            padding: 4,
                                            borderRadius: 8,
                                            alignItems: "center",
                                            justifyContent: "center",
                                            textAlign: "center",
                                            margin: 10,
                                            paddingVertical: 30,
                                            paddingHorizontal: 10,
                                            fontSize: 10,
                                            lineHeight: 1.3,
                                            backgroundColor: "#E6E8F4",
                                            hyphens: "none",
                                        }}
                                    >
                                        <Text>{text}</Text>
                                    </View>
                                ))}
                        </View>
                    </View>
                </View>
                {/* </Page>
            <Page> */}
                <View style={styles.body}>
                    <View style={styles.border2}>
                        <View
                            style={{
                                marginBottom: 20,
                                marginTop: 20,
                            }}
                        >
                            <Text>Spec Overview</Text>
                        </View>
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                fontSize: 5,
                            }}
                        >
                            {spec?.map((group, index) =>
                                Object.entries(group).map(
                                    ([groupName, specs], groupIndex) => (
                                        <View
                                            key={`${index}-${groupIndex}`}
                                            style={{
                                                backgroundColor:
                                                    index % 2 === 0
                                                        ? "#E6E8F4"
                                                        : "white",
                                                padding: 1.1,
                                                paddingHorizontal: 2,
                                                lineHeight: 1.2,
                                            }}
                                        >
                                            {specs?.map((spec, specIndex) => (
                                                <View
                                                    key={specIndex}
                                                    style={{
                                                        flexDirection: "row",
                                                        fontSize: 8,
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            width: 150,
                                                            padding: 1.1,
                                                        }}
                                                    >
                                                        <Text>
                                                            {specIndex === 0
                                                                ? groupName
                                                                : ""}
                                                        </Text>
                                                    </View>
                                                    {spec?.showname?.length >
                                                    0 ? (
                                                        <>
                                                            <View
                                                                style={{
                                                                    width: 100,
                                                                    textAlign:
                                                                        "left",
                                                                    padding: 1.1,
                                                                }}
                                                            >
                                                                <Text>
                                                                    {
                                                                        spec.showname
                                                                    }
                                                                </Text>
                                                            </View>
                                                            <View
                                                                style={{
                                                                    width: 400,
                                                                    padding: 1.1,
                                                                    textAlign:
                                                                        "left",
                                                                }}
                                                            >
                                                                <Text>
                                                                    {spec.value}
                                                                </Text>
                                                            </View>
                                                        </>
                                                    ) : (
                                                        <View
                                                            style={{
                                                                width: 500,
                                                                textAlign:
                                                                    "left",

                                                                padding: 1.1,
                                                            }}
                                                        >
                                                            {" "}
                                                            {/* 如果沒有 spec.showname，則增加寬度 */}
                                                            <Text>
                                                                {spec?.value}
                                                            </Text>
                                                        </View>
                                                    )}
                                                </View>
                                            ))}
                                        </View>
                                    )
                                )
                            )}
                        </View>
                    </View>
                </View>
                <View style={styles.footer}>
                    <Image
                        src={type === "edu" ? footerEdu : footerCorp}
                        alt="header"
                    />
                </View>
            </Page>
        </Document>
    );
};

export default SalesheetPDF;
