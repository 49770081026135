import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import "../onboardscreen.css";
import { Box, Flex, Text } from "@chakra-ui/react";
import Arrow from "../../../assets/onboardscreen/arrow.svg";
// import { stageInfo } from "../OnboardScreenInfo";
import { Fade, ScaleFade, SlideFade, useDisclosure } from "@chakra-ui/react";
import GetstartedButton from "./GetstartedButton";

const TextComponent = ({ item }) => (
    <Text
        textAlign="center"
        fontFamily="Inter"
        fontStyle="normal"
        fontSize="16px"
        fontWeight="400"
        color="#404041"
        lineHeight="normal"
        key={item}
    >
        {item}
    </Text>
);

const Stage = ({ stageInfo}) => {
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [stage, setStage] = useState(0);
    const history = useNavigate();

    const reRenderAndResetAnimation = () => {
        if (stage === stageInfo.length - 1) {
            return;
        }
        onClose();
        setTimeout(() => {
            setStage((prev) => prev + 1);
            setTimeout(() => {
                onOpen();
            }, 300);
        }, 200);
    };

    useEffect(() => {
        onOpen();
    }, []);

    return (
        <Flex
            flexDirection="column"
            alignItems="center"
            h="100%"
            // minHeight="100vh"
            justifyContent="space-between"
            // py="100px"
            paddingTop="10vh"
            paddingBottom="5vh"
        >
            <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                flex="1"
            >
                <Flex alignItems="center" maxHeight="200px">
                    <ScaleFade
                        in={isOpen}
                        transition={{ enter: { duration: 0.4 } }}
                    >
                        <img
                            src={stageInfo[stage]?.pic}
                            alt="product"
                            style={{ height: "200px", maxWidth: "200px" }}
                        />
                    </ScaleFade>
                    {/* 預先渲染下一階段（stage + 1）的圖片 */}
                    <div
                        style={{
                            opacity: 0,
                            position: "absolute",
                            pointerEvents: "none",
                        }}
                    >
                        <img
                            src={stageInfo[stage + 1]?.pic}
                            alt="next product"
                            style={{ height: "200px", maxWidth: "200px" }}
                        />
                    </div>
                </Flex>
                <Flex
                    w="253px"
                    justifyContent="space-between"
                    flexDirection="column"
                    mt="30px"
                    h="180px"
                >
                    <SlideFade
                        in={isOpen}
                        offsetY="20px"
                        transition={{ enter: { duration: 0.4 } }}
                    >
                        <Text
                            textAlign="center"
                            fontSize="28px"
                            fontWeight="700"
                            lineHeight="normal"
                            marginBottom="15px"
                            color="#404041"
                        >
                            {stageInfo[stage]?.title}
                        </Text>

                        {stageInfo[stage]?.content.map((item) => (
                            <TextComponent key={item} item={item} />
                        ))}
                    </SlideFade>
                </Flex>
            </Flex>
            <Flex
                flexDirection="column"
                alignItems="center"
                w="100%"
                maxWidth="400px"
            >
                <Flex
                    w="100%"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="30px"
                    px="30px"
                >
                    <Box>
                        <Text
                            textAlign="center"
                            fontSize="18px"
                            fontWeight="700"
                            lineHeight="normal"
                            color="#404041"
                            m="0"
                            onClick={() => history("/")}
                        >
                            Skip
                        </Text>
                    </Box>
                    <Box _hover={{ cursor: "pointer" }}>
                        {stage === stageInfo.length - 1 ? (
                            <GetstartedButton />
                        ) : (
                            <img
                                src={Arrow}
                                alt="product"
                                onClick={reRenderAndResetAnimation}
                            />
                        )}
                    </Box>
                </Flex>
                <Flex>
                    <Fade in={isOpen} transition={{ enter: { duration: 0.5 } }}>
                        <img src={stageInfo[stage]?.progressBar} alt="stage1" />
                    </Fade>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default Stage;
