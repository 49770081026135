import React, { useEffect, useRef, useState } from "react";
import { Box, Text, CloseButton, Image, VStack, Flex } from "@chakra-ui/react";
import "./style.css";

export default function SvgComponent({ onAnimationEnd }) {
    const upperPartRef = useRef(null);

    useEffect(() => {
        const element = upperPartRef.current;
        element.addEventListener("animationend", onAnimationEnd);

        return () => {
            element.removeEventListener("animationend", onAnimationEnd);
        };
    }, []);
    return (
        <Flex>
            <svg
                width="900"
                height="319"
                viewBox="0 0 219 219"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                ref={upperPartRef}
            >
                <g className="onboard-logo-upper-part">
                    <path
                        d="M109.373 83.1128H109.359C108.744 83.1062 108.192 82.7339 107.956 82.1645L103.118 70.4502C102.794 69.6684 103.166 68.7705 103.95 68.4486C104.732 68.1266 105.63 68.4967 105.952 69.2808L109.41 77.6509L113.061 69.2545C113.398 68.477 114.302 68.1222 115.078 68.4595C115.855 68.7968 116.21 69.7012 115.873 70.4765L110.779 82.1908C110.536 82.7514 109.984 83.1128 109.373 83.1128Z"
                        fill="black"
                    />
                    <path
                        d="M109.5 105.64C108.55 105.64 107.599 105.395 106.749 104.907L85.6005 92.6975C83.9054 91.7186 82.8521 89.8943 82.8521 87.9343V63.5136C82.8521 61.5557 83.9054 59.7314 85.6005 58.7503L106.749 46.5389C108.444 45.56 110.551 45.56 112.248 46.5389L133.397 58.7503C135.092 59.7293 136.146 61.5535 136.146 63.5136V87.9343C136.146 89.8921 135.092 91.7164 133.397 92.6953L112.248 104.905C111.401 105.395 110.45 105.638 109.5 105.638V105.64ZM108.285 49.1954L87.1357 61.4068C86.3845 61.8404 85.9202 62.6463 85.9202 63.5136V87.9343C85.9202 88.8015 86.3867 89.6074 87.1357 90.041L108.285 102.25C109.036 102.684 109.966 102.684 110.718 102.25L131.866 90.041C132.618 89.6074 133.082 88.8015 133.082 87.9343V63.5136C133.082 62.6463 132.615 61.8404 131.866 61.4068L110.718 49.1954C109.966 48.7617 109.036 48.7617 108.285 49.1954Z"
                        fill="black"
                    />
                </g>
                <g className="onboard-logo-lower-part">
                    <path
                        d="M67.0118 153.323H68.9719L77.3968 133.589H75.0163L68.017 150.469L61.0463 133.589H58.5825L67.0075 153.323H67.0118ZM79.5649 135.547H82.0287V133.166H79.5649V135.547ZM79.7051 153.183H81.86V138.711H79.7051V153.183ZM92.3501 153.518C95.0942 153.518 96.8572 152.425 98.3135 150.802L96.9688 149.598C95.7928 150.828 94.3934 151.641 92.4049 151.641C89.8294 151.641 87.6175 149.878 87.3109 146.742H98.7887C98.8172 146.49 98.8172 146.321 98.8172 146.041C98.8172 141.814 96.3535 138.398 92.072 138.398C88.0687 138.398 85.1297 141.757 85.1297 145.927V145.984C85.1297 150.463 88.3775 153.513 92.3523 153.513L92.3501 153.518ZM87.3109 145.148C87.5913 142.32 89.4944 140.22 92.0129 140.22C94.8971 140.22 96.4082 142.515 96.6316 145.148H87.3088H87.3109ZM104.875 153.294H106.778L110.698 141.873L114.59 153.294H116.464L121.558 138.709H119.318L115.512 150.327L111.591 138.654H109.8L105.908 150.327L102.102 138.709H99.8071L104.875 153.294ZM122.577 153.183H126.972L128.763 148.787H137.05L138.842 153.183H143.349L134.95 133.449H130.975L122.577 153.183ZM130.303 144.981L132.907 138.626L135.511 144.981H130.305H130.303ZM143.482 153.183H147.792V146.913H151.18L155.378 153.183H160.418L155.63 146.183C158.122 145.259 159.828 143.273 159.828 140.108V140.051C159.828 138.203 159.241 136.663 158.149 135.573C156.861 134.285 154.929 133.586 152.437 133.586H143.48V153.183H143.482ZM147.792 143.104V137.478H152.076C154.176 137.478 155.464 138.431 155.464 140.277V140.334C155.464 141.985 154.259 143.104 152.161 143.104H147.794H147.792Z"
                        fill="black"
                    />
                    <path
                        d="M76.7705 167.063V162.906H77.2501V164.441H77.2917C77.3268 164.387 77.375 164.319 77.4385 164.233C77.502 164.148 77.5939 164.074 77.71 164.006C77.8283 163.938 77.9903 163.905 78.194 163.905C78.4568 163.905 78.689 163.971 78.8882 164.102C79.0875 164.233 79.2452 164.42 79.3569 164.66C79.4686 164.901 79.5255 165.186 79.5255 165.512C79.5255 165.839 79.4686 166.128 79.3569 166.371C79.2452 166.612 79.0897 166.798 78.8904 166.931C78.6911 167.063 78.4612 167.129 78.2028 167.129C78.0035 167.129 77.8414 167.096 77.721 167.03C77.6005 166.962 77.5085 166.885 77.4428 166.8C77.3771 166.712 77.3268 166.642 77.2917 166.585H77.2348V167.065H76.7727L76.7705 167.063ZM77.2414 165.504C77.2414 165.74 77.2764 165.946 77.3443 166.128C77.4122 166.307 77.5151 166.447 77.6465 166.548C77.7801 166.649 77.9422 166.699 78.1327 166.699C78.3232 166.699 78.5006 166.647 78.6342 166.542C78.77 166.434 78.8707 166.292 78.9386 166.112C79.0087 165.931 79.0415 165.729 79.0415 165.508C79.0415 165.287 79.0087 165.092 78.9408 164.914C78.8751 164.737 78.7744 164.597 78.6386 164.494C78.505 164.389 78.3364 164.339 78.1327 164.339C77.929 164.339 77.7735 164.389 77.6421 164.487C77.5085 164.586 77.41 164.722 77.3421 164.897C77.2742 165.072 77.2414 165.276 77.2414 165.508V165.504ZM80.4519 168.232C80.3709 168.232 80.2986 168.226 80.2351 168.213C80.1716 168.202 80.1278 168.189 80.1037 168.175L80.2263 167.753C80.3424 167.783 80.4453 167.792 80.5351 167.786C80.6249 167.777 80.7038 167.737 80.7716 167.665C80.8417 167.595 80.9074 167.481 80.9644 167.321L81.0541 167.078L79.9022 163.944H80.4212L81.2819 166.428H81.3148L82.1754 163.944H82.6945L81.3717 167.516C81.3126 167.676 81.2381 167.81 81.1505 167.917C81.0629 168.024 80.96 168.103 80.8439 168.153C80.7278 168.206 80.5986 168.23 80.4541 168.23L80.4519 168.232Z"
                        fill="black"
                    />
                    <mask
                        id="mask0_1450_532"
                        style={{ maskType: "luminance" }}
                        maskUnits="userSpaceOnUse"
                        x="83"
                        y="163"
                        width="60"
                        height="11"
                    >
                        <path
                            d="M142.233 163.697H83.8413V173.193H142.233V163.697Z"
                            fill="white"
                        />
                    </mask>
                    <g mask="url(#mask0_1450_532)" />
                    <path
                        d="M92.9945 164.233C92.2609 164.327 92.0857 164.581 91.7922 165.328L88.7437 173.164H88.3824L84.9594 165.168C84.609 164.367 84.4097 164.327 83.8359 164.233V163.9H88.3298V164.233C86.707 164.168 87.1735 164.991 87.5808 165.941L89.3328 170.159L91.0301 165.768C91.4418 164.882 91.5031 164.181 90.1497 164.233V163.9H92.9967V164.233H92.9945Z"
                        fill="#080304"
                    />
                    <path
                        d="M95.8351 172.933H92.6421V172.616C93.1239 172.495 93.3538 172.456 93.3538 171.81V167.868C93.3538 167.263 93.1524 167.171 92.6421 167.077V166.76H95.2044V171.849C95.2044 172.265 95.3139 172.559 95.8351 172.613V172.931V172.933ZM94.2649 163.711C95.5898 163.689 95.5986 165.811 94.2649 165.774C92.8939 165.805 92.9137 163.698 94.2649 163.711Z"
                        fill="#080304"
                    />
                    <path
                        d="M97.9721 169.161C97.9721 167.31 98.4254 167.013 98.9204 167.013C99.9716 167.115 99.6672 168.447 99.7613 169.161H97.9721ZM101.15 171.257C99.7723 173.107 97.9239 171.89 98.0378 169.649H101.402C101.684 166.014 96.3318 165.101 96.1172 169.835C96.1632 173.55 99.803 174.224 101.47 171.445L101.15 171.257Z"
                        fill="#080304"
                    />
                    <path
                        d="M110.808 167.079C110.01 166.95 108.423 172.642 108.066 173.121H107.759L106.421 169.063H106.355L104.724 173.121H104.402C103.981 172.48 102.429 167.106 101.662 167.079V166.764H104.63V167.079C103.464 167.241 104.625 168.525 105.243 170.577L106.156 168.284C105.878 167.548 106.029 167.125 105.284 167.077V166.762H108.414V167.077C108.063 167.106 107.77 167.132 107.77 167.412C107.77 167.692 108.331 169.436 108.572 170.483H108.598C108.913 169.129 110.481 167.114 108.999 167.077V166.762H110.805V167.077H110.803L110.808 167.079Z"
                        fill="#080304"
                    />
                    <path
                        d="M116.739 166.595C115.561 162 110.092 164.926 114.063 166.969C115.537 167.863 117.33 167.882 117.512 170.394C117.512 171.568 116.654 173.182 114.188 173.182C112.659 173.32 111.857 172.083 111.492 173.182H111.113V169.888H111.492C111.759 171.235 112.536 172.742 114.244 172.742C115.85 172.742 115.913 171.424 115.913 171.248C115.972 170.059 114.446 169.693 113.52 169.196C110.114 167.828 110.572 163.765 114.181 163.713C115.532 163.592 116.321 164.797 116.713 163.697H117.113V166.595H116.737H116.739Z"
                        fill="#080304"
                    />
                    <path
                        d="M120.015 169.865C119.667 166.363 122.339 165.658 122.12 169.865C122.398 173.892 119.649 173.498 120.015 169.865ZM118.055 169.865C118.005 174.129 124.11 174.188 124.078 169.865C124.08 165.465 117.998 165.636 118.055 169.865Z"
                        fill="#080304"
                    />
                    <path
                        d="M131.247 172.933H128.109V172.615C128.553 172.574 128.739 172.348 128.739 171.851V168.608C128.739 168.233 128.726 167.497 128.001 167.497C127.462 167.497 126.887 168.152 126.887 168.275V171.851C126.887 172.16 126.928 172.548 127.544 172.615V172.931H124.324V172.615C124.806 172.521 125.034 172.467 125.034 171.81V167.87C125.034 167.265 124.859 167.16 124.324 167.079V166.762H126.871V167.727H126.897C127.381 166.442 130.607 165.826 130.59 168.487V171.853C130.59 172.175 130.59 172.589 131.245 172.618V172.933H131.247Z"
                        fill="#080304"
                    />
                    <path
                        d="M134.791 172.933H131.6V172.616C132.082 172.495 132.31 172.456 132.31 171.81V167.868C132.31 167.263 132.11 167.171 131.6 167.077V166.76H134.162V171.849C134.162 172.265 134.272 172.559 134.791 172.613V172.931V172.933ZM133.221 163.711C134.552 163.689 134.559 165.811 133.221 165.774C131.852 165.805 131.876 163.698 133.221 163.711Z"
                        fill="#080304"
                    />
                    <path
                        d="M140.48 171.71C139.865 172.582 138.983 173.118 137.934 173.118C133.508 172.825 134.758 166.261 138.223 166.592C139.907 166.402 141.317 168.662 139.383 168.909C138.636 168.909 138.636 168.254 138.636 167.532C138.636 167.129 138.29 167.01 138.091 167.01C137.06 167.01 136.968 168.655 136.968 169.257C136.854 171.929 138.906 173.103 140.24 171.465L140.483 171.708L140.48 171.71Z"
                        fill="#080304"
                    />
                    <path
                        d="M140.474 166.393C140.45 165.337 142.086 165.331 142.062 166.393C142.086 167.462 140.45 167.455 140.474 166.393ZM141.271 167.37C142.548 167.407 142.553 165.381 141.271 165.418C139.982 165.381 139.986 167.409 141.271 167.37ZM141.063 166.472H141.261L141.558 166.958H141.749L141.429 166.461C141.854 166.437 141.81 165.762 141.324 165.83H140.893V166.958H141.066V166.472H141.063ZM141.063 166.325V165.973C141.716 165.887 141.718 166.413 141.063 166.325Z"
                        fill="#080304"
                    />
                </g>
            </svg>
        </Flex>
    );
}
