import React, { useEffect, useState } from "react";
import salesheet from "../../assets/viewboard/salesheet.png";
import style from "./Salesheet.module.css";
import headerEdu from "../../assets/salesheet/Header_EDU.png";
import headerCorp from "../../assets/salesheet/Header_Corp.png";
import footerCorp from "../../assets/salesheet/Footer_Corp.png";
import footerEdu from "../../assets/salesheet/Footer_EDU.png";
import e from "../../assets/salesheet/1.png";
import { jsPDF } from "jspdf";
import { PDFDownloadLink, Document, Page } from "@react-pdf/renderer";

const Salesheet = () => {
    const query = new URLSearchParams(window.location.search);
    const productName = query.get("product");
    const img = query.get("img");
    let imgUrl
    if (img === null) {
         imgUrl = salesheet;
    } else {
         imgUrl = "https://gpg-sales.s3.us-west-1.amazonaws.com/" + img;
    }

    console.log(img);
    const [content, setContent] = useState({});
    const [product, setProduct] = useState(productName);
    const [type, setType] = useState("edu");
    const [spec, setSpec] = useState("");
    const getSheet = async () => {
        setProduct(productName);
        await fetch(
            "https://vgsa157d2e.execute-api.us-west-1.amazonaws.com/gpg/productspecs",
            {
                method: "post",
                body: JSON.stringify({ productName: productName }),
            }
        )
            .then(async (res) => {
                const data = await res.json();
                console.log(data.body.product);
                setContent(data.body.product);
                listSpec(data.body.product.specs);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        setType(query.get("type"));
        getSheet();
    }, []);

    const listSpec = (specs) => {
        const organizedData = specs.reduce((acc, spec) => {
            const groupName = spec.groupname.toUpperCase();
            const showname = spec.showname;
            const value = spec.value;

            if (!acc[groupName]) {
                acc[groupName] = [];
            }
            if (value !== "N/A") {
                acc[groupName].push({
                    showname,
                    value,
                });
            }

            return acc;
        }, {});

        const result = Object.entries(organizedData)
            .map(([groupName, specs]) => ({
                [groupName]: specs,
            }))
            .filter((obj) => Object.values(obj)[0].length > 0);

        console.log(result);
        setSpec(result);
    };

    return (
        <Document>
            <div className={style.salesheet}>
                <div className={style.header}>
                    <img
                        src={type === "edu" ? headerEdu : headerCorp}
                        alt="header"
                    ></img>
                    <div className={type !== "edu" ? style.white : undefined}>
                        <small>ViewSonic®</small>
                        <h3>{product && product.toUpperCase()}</h3>
                        <h5>{content.productLine}</h5>
                    </div>
                </div>

                <div className={style.body}>
                    <img src={imgUrl} alt="disable"></img>
                    <div className={style.border}>
                        <img src={content.image} alt="disable"></img>
                        <div>
                            <h5>Overview</h5>
                            <p>{content.overview}</p>
                        </div>
                    </div>

                    <div className={style.tag}>
                        <h5>Benefit Highlight</h5>
                        <div className={style.boxs}>
                            {content.tagline &&
                                content.tagline.map((text, index) => (
                                    <Box key={index} text={text}></Box>
                                ))}
                        </div>
                    </div>

                    <div className={style.border2}>
                        <h5>Spec Overview</h5>
                        <table className="table">
                            {spec && <Tbody specs={spec}></Tbody>}
                        </table>
                    </div>
                </div>

                <div className={style.footer}>
                    <img
                        src={type === "edu" ? footerEdu : footerCorp}
                        alt="header"
                    ></img>
                </div>
            </div>
        </Document>
    );
};

const Tbody = ({ specs }) => {
    return (
        <>
            {specs.map((group, index) => (
                <tbody key={index}>
                    {Object.entries(group).map(([groupName, specs]) => (
                        <React.Fragment key={groupName}>
                            <tr>
                                <td
                                    rowSpan={specs.length + 1}
                                    className="va-center"
                                >
                                    {groupName}
                                </td>
                            </tr>
                            {specs.map((spec, index) => (
                                <tr key={index}>
                                    {spec.showname ? (
                                        <>
                                            <td>{spec.showname}</td>
                                            <td>{spec.value}</td>
                                        </>
                                    ) : (
                                        <td colSpan="2">{spec.value}</td>
                                    )}
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            ))}
        </>
    );
};

const Box = (props) => {
    return <div className={style.box}>{props.text}</div>;
};
export default Salesheet;
