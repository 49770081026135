import React, { useEffect, useState } from "react";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import "./App.css";
import Viewboard from "./component/Home/Home";
import Header from "./component/Header/Header";
import BoardSheet from "./component/Salesheet/BoardSheet";
import Logo from "./component/Splash/Logo";
import Salesheet from "./component/Salesheet/Salesheet";
import viewboardDetails from "./component/Home/viewboardDetails";
import { CountryRegionData } from "react-country-region-selector";
import OnboardScreen from "./component/OnboardScreen/OnboardScreen";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import ReactGA from "react-ga4";
function UsePageView() {
    const location = useLocation();
    useEffect(() => {
        console.log("hiiii");
        ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TOKEN);
    }, []);
    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: location.pathname,
            title: location.pathname,
        });
    }, [location]);

    return null;
}
function App() {
    const [countryList, setCountryList] = useState("");

    useEffect(() => {
        console.log("hiiii");
    }, []);

    useEffect(() => {
        const options = CountryRegionData.map((country) => ({
            value: country[0],
            label: country[0],
        }));
        setCountryList(options);
    }, []);

    return (
        <>
            <ChakraProvider>
                <BrowserRouter>
                    <Flex flexDirection="column" justifyContent="center">
                        <Header />
                        <UsePageView />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <Viewboard
                                        viewboardDetails={
                                            viewboardDetails.viewboard
                                        }
                                        countryList={countryList}
                                    />
                                }
                            />
                             {/* <Route
                                path="/cde"
                                element={
                                    <Viewboard
                                        viewboardDetails={
                                            viewboardDetails.CDE
                                        }
                                        countryList={countryList}
                                    />
                                }
                            /> */}
                            <Route
                                path="/viewled"
                                element={
                                    <Viewboard
                                        viewboardDetails={
                                            viewboardDetails.viewLED
                                        }
                                        countryList={countryList}
                                    />
                                }
                            />
                            <Route
                                path="/cde"
                                element={
                                    <Viewboard
                                        viewboardDetails={
                                            viewboardDetails.CDE
                                        }
                                        countryList={countryList}
                                    />
                                }
                            />
                            <Route
                                path="/stand"
                                element={
                                    <Viewboard
                                        viewboardDetails={
                                            viewboardDetails.stand
                                        }
                                        countryList={countryList}
                                    />
                                }
                            />

                            <Route
                                path="/boardsheet"
                                element={<BoardSheet />}
                            />
                            <Route path="/salesheet" element={<Salesheet />} />
                            <Route path="/splash" element={<Logo />} />
                            <Route path="/help" element={<OnboardScreen />} />
                        </Routes>
                    </Flex>
                    {/* </UsePageView> */}
                </BrowserRouter>
            </ChakraProvider>
        </>
    );
}

export default App;
