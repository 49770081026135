import React, { useEffect, useState, useRef } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "../onboardscreen.css";

const GetstartedButton = () => {
    const circleRef = useRef(null);
    const [textClass, setTextClass] = useState("");
    const [boxClass, setBoxClass] = useState("");
    const [circleClass, setCircleClass] = useState("");
    const history = useNavigate();

    const endAnimation = () => {
        history("/");
    };
    const handleClick = () => {
        setTextClass("text-collapse");

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                setBoxClass("fade-out");
                requestAnimationFrame(() => {
                    setCircleClass("circle");
                    circleRef.current.addEventListener(
                        "animationend",
                        endAnimation
                    );
                });
            });
        });
    };
    return (
        <Box position="relative" w="163px" h="44px">
            <Flex
                bg="#db0025"
                borderRadius="36px"
                alignItems="center"
                justifyContent="center"
                className={`start ${boxClass}`}
                onClick={handleClick}
                position="absolute"
            >
                <Text position="absolute" className={`starttext ${textClass}`}>
                    Get Started
                </Text>
            </Flex>
            <Box
                hidden={circleClass === ""}
                className={`${circleClass}`}
                position="absolute"
                zIndex={circleClass === "" ? -1 : 1}
                right={0}
                // zIndex={1}
                w="40px"
                h="40px"
                bg="#db0025"
                // bg="black"
                borderRadius="50%"
                ref={circleRef}
            ></Box>
        </Box>
    );
};

export default GetstartedButton;
