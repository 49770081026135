import React, { useState } from "react";
import salesheet from "../../assets/viewboard/salesheet.png"
import board52 from "../../assets/viewboard/52board.png"
import icon1 from "../../assets/viewboard/icon1.png"
import icon2 from "../../assets/viewboard/icon2.png"
import icon3 from "../../assets/viewboard/icon3.png"
import icon4 from "../../assets/viewboard/icon4.png"
import icon5 from "../../assets/viewboard/icon5.png"
import icon6 from "../../assets/viewboard/icon6.png"
// import "./BoardSheet.css"


function BoardSheet() {
    const query = new URLSearchParams(window.location.search);
    const img = query.get('img');
    if (img === null) {
        var imgUrl = salesheet
    } else {
        var imgUrl = "https://gpg-sales.s3.us-west-1.amazonaws.com/" + img

    }
    console.log(img)

    return (
        <>

            <div className="bg-green">
                <h3>ViewSonic<sup>®</sup></h3>
                <h2>ViewBoard</h2>
                <h2>IFP52 Series</h2>
            </div>
            <img src={imgUrl} className="img-fluid"></img>
            <div className="py-3 ps-3">
                <h2>CLASSROOM COLLABORATION</h2>
                <h3 className="green py-2">Interactive displays for hybrid learning</h3>
                <p>The <b>ViewSonic<sup>®</sup> ViewBoard<sup>®</sup> IFP52 series</b> of interactive flat
                    panel displays feature next-generation whiteboard technologies for immersive learning in any classroom environment. Featuring a high-accuracy touchscreen and intelligent palm recognition technology, these interactive displays deliver an intuitive digital whiteboard experience for everyone from the youngest of students to the most experienced teachers. Plus, thanks to stunning 4K resolution, stereo sound and a beamforming microphone, these displays deliver dynamic audiovisuals for everyone in the classroom as well those learning remotely.</p>
                <img src={board52} className="img-fluid"></img>
            </div>
            <div className="bg-gray">
                <div className="row align-items-center">
                    <div className="col-4 d-flex justify-content-center">
                        <img src={icon1} className=""></img>
                    </div>
                    <div className="col-8 align-items-center">
                        <p className="green">POWERFUL 45W<br />
                            STEREO SOUNDBAR AND MICROPHONE ARRAY</p>
                    </div>
                    <div className="col-4 d-flex justify-content-center">
                        <img src={icon2} className=""></img>
                    </div>
                    <div className="col-8">
                        <p className="green">VERSATILE USB-C CONNECTIVITY WITH
                            UP TO 60W POWER
                            DELIVERY DEVICE CONTROL</p>

                    </div>
                    <div className="col-4 d-flex justify-content-center">
                        <img src={icon3} className=""></img>
                    </div>
                    <div className="col-8">
                        <p className="green">BEAMFORMING
                            MICROPHONE</p>

                    </div>
                    <div className="col-4 d-flex justify-content-center">
                        <img src={icon4} className=""></img>
                    </div>
                    <div className="col-8">
                        <p className="green">WIRELESS CONTENT
                            SHARING WITH
                            VIEWBOARD CAST<sup>™</sup></p>

                    </div>
                    <div className="col-4 d-flex justify-content-center">
                        <img src={icon5} className=""></img>
                    </div>
                    <div className="col-8">
                        <p className="green">INTUITIVE ANNOTATION
                            WITH MYVIEWBOARD<sup>™</sup> SOFTWARE SUITE</p>

                    </div>
                    <div className="col-4 d-flex justify-content-center">
                        <img src={icon6} className=""></img>
                    </div>
                    <div className="col-8">
                        <p className="green">SIMPLE DEVICE
                            MANAGEMENT WITH MYVIEWBOARD™ MANAGER</p>

                    </div>
                </div>
            </div>
            <table class="table table-striped mt-2">
                <thead>
                    <tr>
                        <th scope="col" colSpan="2"></th>
                        <th scope="col" colSpan="3" className="text-center">ViewBoard IFP52 Series</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row" rowSpan="7" className="va-center">DISPLAY</th>
                        <th>SIZE</th>
                        <td>65"</td>
                        <td>75"</td>
                        <td>86"</td>
                    </tr>
                    <tr>
                        <th scope="row">RESOLUTION</th>
                        <td colSpan={3}>4K UHD 3840 x 2160</td>
                    </tr>
                    <tr>
                        <th scope="row">CONTRAST</th>
                        <td colspan="3">Static 1200:1; Dynamic 5000:1</td>
                    </tr>
                    <tr>
                        <th scope="row">BRIGHTNESS</th>
                        <td colSpan={2}>350 Nits</td>
                        <td>400 Nits</td>
                    </tr>
                    <tr>
                        <th scope="row">FREQUENCY</th>
                        <td colSpan={3}>60Hz</td>
                    </tr>
                    <tr>
                        <th scope="row">BACKLIGHT LIFE</th>
                        <td colSpan={3}>50000 Hrs</td>
                    </tr>
                    <tr>
                        <th scope="row">GLASS HARDNESS</th>
                        <td colSpan={3}>9H</td>
                    </tr>

                    <tr>
                        <th scope="row" rowSpan="5" className="va-center">TOUCH</th>
                        <th scope="row">TECHNOLOGY</th>
                        <td colSpan={3}>ViewSonic Ultra Fine Touch (IR)</td>
                    </tr>
                    <tr>
                        <th scope="row">TOUCH POINT</th>
                        <td colSpan={3}>33</td>
                    </tr>
                    <tr>
                        <th scope="row">TIP DETECTION</th>
                        <td colspan={3}>8mm and 2mm</td>
                    </tr>
                    <tr>
                        <th scope="row">DETECTION SUPPORT</th>
                        <td colSpan={3}>Palm and Object Recognition</td>
                    </tr>
                    <tr>
                        <th scope="row">WINDOWS INKING SUPPORT</th>
                        <td colSpan={3}>Yes</td>
                    </tr>

                    <tr>
                        <th scope="row" rowSpan="3" className="va-center">INPUT CONNECTIONS</th>
                        <th scope="row">HDMI</th>
                        <td colSpan={3}>HDMI 2.0 x 3, VGA x 1, Audio-In x 1, Audio-Out x 1, SPDIF x 1, RJ45 x 1,
                            RS232 x 1, USB 3.0 x4, USB 2.0 x 1, USB-C x 1, USB-B (Touch) x 2</td>
                    </tr>
                    <tr>
                        <th scope="row">USB-C</th>
                        <td colSpan={3}>60W Power Delivery, BYOM Support</td>
                    </tr>
                    <tr>
                        <th scope="row">OTHERS</th>
                        <td colspan={3}>SmartPort USB Technology</td>
                    </tr>
                </tbody>
            </table>
            <div className="p-3 footer">
                <h3 className="red pb-3">For more information contact your ViewSonic representative today.</h3>
                <h4>ViewSonic Sales: (909) 444-8964 or frank.lin@viewsonic.com</h4>
            </div>
        </>
    );
}

export default BoardSheet;
