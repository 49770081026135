import React from "react";
import styles from "./custommodal.module.css";

const CustomModal = ({ isOpen, onRequestClose, children }) => {
    return (
        <div className={`${styles["modal-overlay"]} ${
            isOpen ? styles["slide-in-right"] : styles["slide-out-right"]
        }`} onClick={onRequestClose}>
            <div
                className={`${styles["modal-content"]} ${
                    isOpen ? styles["slide-in-right"] : styles["slide-out-right"]
                }`}
                onClick={(e) => e.stopPropagation()} // Prevent clicks inside the modal from closing it
            >
                {children}
            </div>
        </div>
    );
};

export default CustomModal;
