import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import Modal from "react-modal";
import CustomSalesheetModal from "../CustomSalesheetModal/CustomSalesheetModal";
import ProductCard from "../ProductCard/ProductCard";
import viewboardList from "./viewboardModelList";
import Logo from "../Splash/Logo";

const customStyles = {
    overlay: {
        position: "fixed",
        zIndex: "1056",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        minWidth: "45%",
        maxWidth: "80%",
        borderRadius: "16px",
        border: "none",
        padding: "2.2rem",
    },
};

function Home({ viewboardDetails, countryList }) {
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [country, setCountry] = useState("");
    const imgRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const modelRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [modelName, setModelName] = useState("");

    const closeAllModal = () => {
        setOpen2(false);
        let customsheet = document.getElementById("closeCustomSheet");
        customsheet.click();
    };
    const handleAnimationEnd = () => {
        setIsLoading(false);
    };
    useEffect(() => {
        setIsLoading(true);
        localStorage.setItem("product", "ifp6552");
    }, [viewboardDetails]);

    return (
        <>
            {isLoading ? (
                <>
                    <Logo onAnimationEnd={handleAnimationEnd}></Logo>
                </>
            ) : (
                <>
                    <div className="accordion m-2" id="accordionExample">
                        {viewboardDetails.series &&
                            Object.values(viewboardDetails.series).map(
                                (value, index) => {
                                    return (
                                        <ProductCard
                                            key={`collapse${index}`}
                                            collapseId={`collapse${index}`}
                                            series={value}
                                            setModelName={setModelName}
                                            defaultArModel={value.size[0].model}
                                            seriesName={
                                                viewboardDetails.seriesName ??
                                                ""
                                            }
                                        />
                                    );
                                }
                            )}
                    </div>

                    <div className="d-none">
                        {Object.entries(viewboardList).map(([key, value]) => {
                            return (
                                <model-viewer
                                    src={value}
                                    ar
                                    style={{
                                        arStatus: "session-started",
                                    }}
                                    ar-placement="wall"
                                    ar-modes="webxr scene-viewer quick-look"
                                    camera-controls
                                    ar-tracking="tracking"
                                    ar-scale="fixed"
                                    alt="A 3D model of some wall art"
                                    id={key}
                                    key={key}
                                ></model-viewer>
                            );
                        })}
                    </div>

                    <CustomSalesheetModal
                        imgRef={imgRef}
                        country={country}
                        setCountry={setCountry}
                        countryList={countryList}
                        modelName={modelName}
                        modelRef={modelRef}
                        nameRef={nameRef}
                        emailRef={emailRef}
                        setOpen2={setOpen2}
                    />

                    <Modal
                        isOpen={open2}
                        onRequestClose={closeAllModal}
                        style={customStyles}
                        ariaHideApp={false}
                    >
                        <div className="text-center">
                            <i className="bi bi-check-lg check"></i>
                            <h2>Success!</h2>
                            <h4 className="mt-2">
                                Your Salesheet is on it’s way to your email
                                inbox.
                            </h4>
                            <p className="mt-2">
                                If you don’t see it, be sure to check your spam
                                folder
                            </p>
                            <div className="d-grid gap-2 col-12 mx-auto mt-1">
                                <button
                                    className="btn btn-red"
                                    type="button"
                                    onClick={closeAllModal}
                                >
                                    <h5>CLOSE</h5>
                                </button>
                            </div>
                        </div>
                    </Modal>

                    <Modal
                        isOpen={open3}
                        onRequestClose={() => {
                            setOpen3(false);
                            setOpen(false);
                        }}
                        style={customStyles}
                        ariaHideApp={false}
                    >
                        <div className="text-center">
                            <i className="bi bi-check-lg check"></i>
                            <h2>Success!</h2>
                            <h4 className="mt-2">
                                One of our sales people will contact you shortly
                                to arrange a demo
                            </h4>
                            <p className="mt-2">
                                If you don’t see it, be sure to check your spam
                                folder
                            </p>
                            <div className="d-grid gap-2 col-12 mx-auto mt-1">
                                <button
                                    className="btn btn-red"
                                    type="button"
                                    onClick={() => {
                                        setOpen3(false);
                                        setOpen(false);
                                    }}
                                >
                                    <h5>CLOSE</h5>
                                </button>
                            </div>
                        </div>
                    </Modal>
                </>
            )}
        </>
    );
}

export default Home;
