import vbImg52 from "../../assets/viewboard/ifp52/ifp52.jpg";
import vbImg52Crop from "../../assets/viewboard/ifp52/ifp62_f.jpg";
import vbImg52Class from "../../assets/viewboard/ifp52/ifp32_class.png";
import vbImg52Board from "../../assets/viewboard/ifp52/ifp32_board.png";
import vbImg32 from "../../assets/viewboard/ifp32/ifp32.png";
import vbImg32Crop from "../../assets/viewboard/ifp32/ifp32_crop.jpg";
import vbImg62 from "../../assets/viewboard/ifp62/ifp50.png";
import vbImg62Crop from "../../assets/viewboard/ifp62/ifp62_crop.jpg";
import vbImg50 from "../../assets/viewboard/ifp50/ifp50.png";
import vbImg50Crop from "../../assets/viewboard/ifp50/ifp32_crop.jpg";
import ld135 from "../../assets/viewled/ld135.jpg";
import lds135 from "../../assets/viewled/lds135.jpg";
import ld135_151_stand from "../../assets/viewled/LD135_151_stand.png";
import ld163 from "../../assets/viewled/LD163_181_stand.png";
import vbImg105 from "../../assets/viewboard/ifp105s/IFP105S_education.jpg";
import vbImg105Crop from "../../assets/viewboard/ifp105s/IFP105S_corporate.jpg";
import vbImg105Class from "../../assets/viewboard/ifp105s/IFP105S_classroom.jpg";
import vbImg105Board from "../../assets/viewboard/ifp105s/IFP105S_boardroom.jpg";
import vbImg9850 from "../../assets/viewboard/ifp9850/ifp9850_education.png";
import vbImg9850Crop from "../../assets/viewboard/ifp9850/ifp9850_corporate.png";
import vbImg9850Class from "../../assets/viewboard/ifp9850/ifp9850_classroom.png";
import vbImg9850Board from "../../assets/viewboard/ifp9850/ifp9850_boardroom.png";
import cde30ImgBoard from "../../assets/viewboard/CDE30/CDE30_boardroom.png";
import cde30ImgCampus from "../../assets/viewboard/CDE30/CDE30_campus.png";
import cde30ImgCrop from "../../assets/viewboard/CDE30/CDE30_corporate.png";
const viewboardDetails = {
    viewboard: {
        seriesName: "ViewBoard",
        series: {
            series62: {
                name: "IFP62 Series",
                series: "62",
                size: [
                    { id: "option10", size: "65", model: "#ifp6562" },
                    { id: "option11", size: "75", model: "#ifp7562" },
                    { id: "option12", size: "86", model: "#ifp8662" },
                ],
                inscreen_graphic: [
                    { label: "Education", value: "edu", id: "inlineRadio621" },
                    { label: "Corporate", value: "crop", id: "inlineRadio622" },
                    {
                        label: "Classroom",
                        value: "class",
                        id: "inlineRadio623",
                    },
                    {
                        label: "Boardroom",
                        value: "board",
                        id: "inlineRadio624",
                    },
                ],
                imageMapping: {
                    edu: vbImg62,
                    crop: vbImg62Crop,
                    class: vbImg52Class,
                    board: vbImg52Board,
                },
            },
            series32: {
                name: "IFP32 Series",
                series: "32",
                size: [
                    { id: "option7", size: "65", model: "#ifp6532" },
                    { id: "option8", size: "75", model: "#ifp7532" },
                    { id: "option9", size: "86", model: "#ifp8632" },
                ],
                inscreen_graphic: [
                    { label: "Education", value: "edu", id: "inlineRadi321" },
                    { label: "Corporate", value: "crop", id: "inlineRadio322" },
                    {
                        label: "Classroom",
                        value: "class",
                        id: "inlineRadio323",
                    },
                    {
                        label: "Boardroom",
                        value: "board",
                        id: "inlineRadio324",
                    },
                ],
                imageMapping: {
                    edu: vbImg32,
                    crop: vbImg32Crop,
                    class: vbImg52Class,
                    board: vbImg52Board,
                },
            },
            series50: {
                name: "IFP50 Series",
                series: "50",
                size: [
                    { id: "option4", size: "65", model: "#ifp6550" },
                    { id: "option5", size: "75", model: "#ifp7550" },
                    { id: "option6", size: "86", model: "#ifp8650" },
                ],
                inscreen_graphic: [
                    { label: "Education", value: "edu", id: "inlineRadi501" },
                    { label: "Corporate", value: "crop", id: "inlineRadio502" },
                    {
                        label: "Classroom",
                        value: "class",
                        id: "inlineRadio503",
                    },
                    {
                        label: "Boardroom",
                        value: "board",
                        id: "inlineRadio504",
                    },
                ],
                imageMapping: {
                    edu: vbImg50,
                    crop: vbImg50Crop,
                    class: vbImg52Class,
                    board: vbImg52Board,
                },
            },
            series52: {
                name: "IFP52 Series",
                series: "52",
                size: [
                    { id: "option1", size: "65", model: "#ifp6552" },
                    { id: "option2", size: "75", model: "#ifp7552" },
                    { id: "option3", size: "86", model: "#ifp8652" },
                ],
                inscreen_graphic: [
                    { label: "Education", value: "edu", id: "inlineRadio521" },
                    { label: "Corporate", value: "crop", id: "inlineRadio522" },
                    {
                        label: "Classroom",
                        value: "class",
                        id: "inlineRadio523",
                    },
                    {
                        label: "Boardroom",
                        value: "board",
                        id: "inlineRadio524",
                    },
                ],
                imageMapping: {
                    edu: vbImg52,
                    crop: vbImg52Crop,
                    class: vbImg52Class,
                    board: vbImg52Board,
                },
            },
            series105: {
                name: "IFP105 Series",
                series: "105",
                size: [{ id: "option1", size: "105", model: "#ifp105s" }],
                inscreen_graphic: [
                    {
                        label: "Education",
                        value: "edu",
                        id: "inlineRadio105se",
                    },
                    {
                        label: "Corporate",
                        value: "crop",
                        id: "inlineRadio105sc",
                    },
                    {
                        label: "Classroom",
                        value: "class",
                        id: "inlineRadio105scl",
                    },
                    {
                        label: "Boardroom",
                        value: "board",
                        id: "inlineRadio105sb",
                    },
                ],
                imageMapping: {
                    edu: vbImg105,
                    crop: vbImg105Crop,
                    class: vbImg105Class,
                    board: vbImg105Board,
                },
            },
            series9850: {
                name: "IFP9850 Series",
                series: "9850",
                size: [{ id: "option1", size: "98", model: "#ifp9850-4" }],
                inscreen_graphic: [
                    {
                        label: "Education",
                        value: "edu",
                        id: "inlineRadio98501",
                    },
                    {
                        label: "Corporate",
                        value: "crop",
                        id: "inlineRadio98502",
                    },
                    {
                        label: "Classroom",
                        value: "class",
                        id: "inlineRadio98503",
                    },
                    {
                        label: "Boardroom",
                        value: "board",
                        id: "inlineRadio98504",
                    },
                ],
                imageMapping: {
                    edu: vbImg9850,
                    crop: vbImg9850Crop,
                    class: vbImg9850Class,
                    board: vbImg9850Board,
                },
            },
        },
    },
    viewLED: {
        seriesName: "Direct View",
        series: {
            LD135151: {
                name: "LED LD135-151",
                size: [{ id: "option1", size: "135", model: "#LD135-151" }],
                inscreen_graphic: [
                    {
                        label: "Corporate",
                        value: "crop",
                        id: "inlineRadio1",
                    },
                ],
                imageMapping: {
                    crop: ld135,
                },
            },
            LD163181: {
                name: "LED LD163-181",
                size: [{ id: "option2", size: "163", model: "#LD163-181" }],
                inscreen_graphic: [
                    {
                        label: "Corporate",
                        value: "crop",
                        id: "inlineRadio2",
                    },
                ],
                imageMapping: {
                    crop: ld135,
                },
            },
            LDP216251: {
                name: "LDP216-251",
                size: [{ id: "option2", size: "216", model: "#LDP216-251" }],
                inscreen_graphic: [
                    {
                        label: "Corporate",
                        value: "crop",
                        id: "inlineRadio2",
                    },
                ],
                imageMapping: {
                    crop: ld135,
                },
            },
        },
    },
    stand: {
        seriesName: "Direct View",
        series: {
            LDS135152: {
                name: "LED LDS135-152",
                feet: true,
                size: [{ id: "option1", size: "135", model: "#LDS135-152" }],
                inscreen_graphic: [
                    {
                        label: "Corporate",
                        value: "crop",
                        id: "inlineRadio1",
                    },
                ],
                imageMapping: {
                    crop: lds135,
                },
            },
            LD135151: {
                name: "LED LD135-151",
                labelfeet: true,
                feet: true,

                size: [
                    {
                        id: "option1",
                        size: "135",
                        model: "#LD135-151",
                    },
                ],
                inscreen_graphic: [
                    {
                        label: "Corporate",
                        value: "crop",
                        id: "inlineRadio1",
                    },
                ],
                imageMapping: {
                    crop: ld135_151_stand,
                },
            },
            LDM163181: {
                name: "LED LDM163-181",
                labelfeet: true,
                feet: true,

                size: [{ id: "option1", size: "163", model: "#LDM163-181" }],
                inscreen_graphic: [
                    {
                        label: "Corporate",
                        value: "crop",
                        id: "inlineRadio1",
                    },
                ],
                imageMapping: {
                    crop: ld163,
                },
            },
        },
    },
    CDE: {
            seriesName: "Commercial Display",
            series: {
                CDE30: {
                    name: "CDE30 Series",
                    size: [
                        { id: "option1", size: "43", model: "#CDE4330" },
                        { id: "option2", size: "55", model: "#CDE5530" },
                        { id: "option3", size: "65", model: "#CDE6530" },
                        { id: "option4", size: "75", model: "#CDE7530" },
                        { id: "option5", size: "86", model: "#CDE8630" },
                        { id: "option6", size: "98", model: "#CDE9830" },
                    ],
                    inscreen_graphic: [
                        {
                            id: "inlineRadio1",
                            label: "Corporate",
                            value: "crop",
                        },
                        {
                            id: "inlineRadio2",
                            label: "Campus",
                            value: "campus",
                        },
                        {
                            id: "inlineRadio3",
                            label: "BoardRoom",
                            value: "board",
                        },
                    ],
                    imageMapping: {
                        crop: cde30ImgCrop,
                        campus: cde30ImgCampus,
                        board: cde30ImgBoard,
                    },
                },
            },
    },
};

export default viewboardDetails;
